@import "assets/scss/mixins.scss";

.cell {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;
  &::after {
    @include border-style;
  }
}

.inner-cell {
  position: relative;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  &:last-child {
    &::after {
      display: none;
    }
  }
  &:first-child {
      padding-left: 0;
  }
  &::after {
    @include border-style;
  }
}

.cell-text {
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article-info {
  background-color: var(--primary-background);
  width: fit-content;
  padding: 6px 8px;
  border-radius: 20px;
}