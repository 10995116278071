:root {
  .done {
    width: 120px;
    background-color: var(--action-color);
    border-radius: 8px;
    color: var(--bg-color-white) !important;
    overflow: hidden;
    margin-left: 16px !important;
    &:hover {
      background-color: var(--done-button-hover-background);
    }

    &:active {
      background-color: var(--done-button-active-background);
    }
  }

  .cancel {
    width: 120px;
    background-color: var(--secondary-bacground-color);
    border-radius: 8px;
    color: var(--cancel-color) !important;
    overflow: hidden;
    &:hover {
      background-color: var(--cancel-button-hover-background);
    }

    &:active {
      background-color: var(--cancel-button-active-background);
    }
  }

  .mat-mdc-form-field.mat-focused {
    border: 1px solid var(--action-color) !important;
  }

  .mat-form-field-invalid {
    border-color: red !important;
  }
  .mat-form-field-disabled {
    background-color: var(--primary-background);
  }

  .mat-mdc-button-disabled {
    background-color: var(--action-color-disabled);
  }

  .mat-mdc-dialog-content {
    background-color: var(--bg-color-white);
    padding: 20px !important;
  }

  .mat-mdc-dialog-surface {
    background-color: var(--bg-color-white);
    border-radius: 12px;
  }

  .mat-mdc-button-persistent-ripple::before {
    background-color: var(--secondary-bacground-color) !important;
  }

  .mat-ripple-element {
    display: none;
  }

  .error {
    position: absolute;
    color: red;
    top: 70px;
    font-family: 'Open-Sans';

    .dialog-title-wrapper {
      border-bottom: 1px solid var(--primary-border-color);
    }
    .mat-mdc-dialog-actions {
      border-top: 1px solid var(--primary-border-color);
    }
  }

  .title {
    font-family: 'Open-Sans', sans-serif;
  }

  .placeholder-primary::placeholder {
    color: var(--text-color-primary) !important;
    opacity: 1;
  }

  .error-request {
    text-wrap: wrap !important;
    text-align: left;
    line-height: 20px !important;
    margin-right: 10px !important;
    width: calc(100% - 266px);
    color: var(--error-color);
  }
  
  .buttons-tooltip {
    background-color: var(--header-location-border);
    color: var(--bg-color-white);
    padding: 2px 8px 4px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    width: fit-content;
  }

  .wrapper-grid-two-columns {
    display: grid;
    min-width: 572px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
}

