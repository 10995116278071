@import "assets/scss/mixins.scss";

.mat-mdc-dialog-surface {
  background-color: var(--bg-color-white) !important;
}

.cdk-overlay-backdrop {
  pointer-events: none;
}

.cdk-overlay-pane {
  pointer-events: all;
}

.mat-mdc-dialog-content, .page-content-wrapper {
  background-color: var(--bg-color-white) !important;
  padding: var(--field-gap) !important;

  .mat-mdc-form-field {
    width: 180px;
  }

  .mat-focused {
    border: 1px solid var(--bg-color-black);
  }

  .mat-mdc-form-field-infix {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .mat-mdc-button-persistent-ripple::before {
    background-color: var(--secondary-bacground-color) !important;
  }

  .mat-ripple-element {
    display: none;
  }

  .mat-accent.mat-mdc-radio-button {
    --mdc-radio-selected-focus-icon-color: var(--action-color) !important;
    --mdc-radio-selected-hover-icon-color: var(--action-color) !important;
    --mdc-radio-selected-icon-color: var(--action-color) !important;
    --mdc-radio-selected-pressed-icon-color: var(--action-color) !important;
    --mat-radio-checked-ripple-color: var(--action-color) !important;
  }

  .mat-mdc-autocomplete-panel {
    background-color: var(--bg-color-white) !important;
  }

  .dropdown-menu {
    background-color: var(--bg-color-white);
    border: 1px solid var(--primary-border-color);
    z-index: 1;
  }

  .form-select {
    background-color: var(--bg-color-white);
    border: 1px solid var(--primary-border-color);
    border-radius: 8px;
    color: var(--primary-border-colors);
  }

  .disabled {
    color: var(--text-color-primary) !important;
  }

  .blue-text {
    text-align: end;
    color: var(--action-color);
  }

  .btn-light:hover {
    background-color: var(--action-color) !important;
    color: var(--bg-color-white) !important;
  }

  .bg-primary {
    background-color: var(--action-color) !important;
    color: var(--bg-color-white) !important;
  }

  .data-input-no-border:focus {
    outline: none;
    border: none;
  }

  .data-input {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Open-Sans', sans-serif;
    padding: 8px 12px 8px 20px;
  }


  .mat-mdc-select-placeholder {
    color: var(--text-color-primary) !important;
  }

  .mat-mdc-dialog-actions {
    padding: 12px 16px;
  }

  .title {
    overflow: hidden;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .custom-select-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }

  .rotated {
    transform: translateY(-50%) rotate(180deg);
  }

  .select-input-item {
    display: flex;
    flex-direction: column;
  }

  .form-field-wrapper {
    position: relative;
  }

}

.dialog-title-wrapper {
  display: flex;
  align-items: center;
  .dialog-title {
    @include dialog-title;
  }
}

.dialog-confirm-title-wrapper {
  display: flex;
  align-items: center;
  padding-top: 20px;
  position: relative;
  .dialog-title {
    @include dialog-confirm-title;
  }
}

.dialog-confirm-title-wrapper, .dialog-title-wrapper {
  .img-title {
    width: var(--action-button-icon-width);
    height: var(--action-button-icon-height);
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 20px;
  }
}

.dialog-content-confirm-wrapper{
  position: relative;
  .close-button {
    width: var(--action-button-icon-width);
    height: var(--action-button-icon-height);
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 20px;
  }
}

.dialog-confirm-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: var(--dialog-confirm-size);

  .confirm-icon {
      width: var(--big-action-icon-size);
      height: var(--big-action-icon-size);
      border-radius: var(--dialog-confirm-border-radius);
      background-color: var(--primary-background);
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .title {
      line-height: var(--default-line-height);
      font-size: var(--default-font-size);
      font-family: var(--default-font);
      color:var(--cancel-color);
  }
}