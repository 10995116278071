.done-confirm-dialog {
  width: 272px !important;
  background-color: var(--action-color) !important;
  border-radius: var(--default-button-radius) !important;
  color: var(--bg-color-white) !important;
  overflow: hidden;
  gap: 16px;
  &:hover {
    background-color: var(--done-button-hover-background);
  }

  &:active {
    background-color: var(--done-button-active-background);
  }
}

.cancel-confirm-dialog {
  width: 272px !important;
  background-color: var(--secondary-bacground-color) !important;
  border-radius: var(--default-button-radius) !important;
  color: var(--cancel-color) !important;
  overflow: hidden;
  &:hover {
    background-color: var(--cancel-button-hover-background);
  }

  &:active {
    background-color: var(--cancel-button-active-background);
  }
}

.done {
  width: 120px;
  background-color: var(--action-color);
  border-radius: var(--default-button-radius);
  color: var(--bg-color-white) !important;
  overflow: hidden;
  gap: 16px;
  &:hover {
    background-color: var(--done-button-hover-background);
  }

  &:active {
    background-color: var(--done-button-active-background);
  }
}

.cancel {
  width: 120px;
  background-color: var(--secondary-bacground-color);
  border-radius: var(--default-button-radius);
  color: var(--cancel-color) !important;
  overflow: hidden;
  &:hover {
    background-color: var(--cancel-button-hover-background);
  }

  &:active {
    background-color: var(--cancel-button-active-background);
  }
}


.mdc-switch__icons{
  display: none;
}

.mdc-switch__track::before{
background-color: rgba(126, 126, 126, 1) !important;
}
.mdc-switch__shadow{
background-color: white;
}