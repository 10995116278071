h2.page-title {
  color: var(--text-color-secondary);
  line-height: var(--page-title-line-height) !important;
  font-size: var(--page-title-font-size);
  text-align: center;
  padding: var(--title-vertical-padding) var(--title-horizontal-padding);
  font-weight: 400;
  margin: 0;
}

span.highlighted-string {
  font-weight: 600;
  background-color: var(--highlighted-text-background);
}

.headline {
  line-height: var(--heading-line-height);
  font-size: var(--heading-font-size);
  margin-top: 24px;
  margin-bottom: 8px;
  font-family: var(--default-font);
  color:var(--cancel-color);
}