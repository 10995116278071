@import "assets/scss/fonts.scss";
@import "assets/scss/style.scss";
@import "assets/scss/global.scss";
@import "assets/scss/dialogs.scss";
@import "assets/scss/typography.scss";
@import "assets/scss/forms.scss";
@import "assets/scss/tables.scss";
@import "assets/scss/buttons.scss";


html, body { height: 100%; }
body { margin: 0; font-family: 'Open-Sans';}
