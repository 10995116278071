@mixin border-style {
  content: '';
  position: absolute;
  height: 100%;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--primary-border-color);
}

@mixin dialog-title($width: var(--dialog-title-width), $margin-right: var(--action-button-width)) {
  text-align: center;
  width: $width;
  margin: 0 $margin-right 0 var(--title-horizontal-padding);
}

@mixin dialog-confirm-title($width: var(--dialog-title-width), $margin-right: var(--action-button-width), $height: var(--action-button-icon-width)) {
  text-align: center;
  width: $width;
  margin: 0 $margin-right 0 var(--title-horizontal-padding);
  height: 0 $margin-right 0 var(--title-horizontal-padding);
}

